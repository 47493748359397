import React, { useState, useEffect } from 'react'
import AdminApi from './../../../../api/admin-api/admin-api'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBAlert,
  MDBCollapse,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabPane,
  MDBTabContent,
} from 'mdbreact'
import quotationFormBgImage from './../../../../assets/images/home-bg-image-1.png'
import { phoneToView } from '../../../../shared/formatters/telephone.formatter'
import ReCAPTCHA from 'react-google-recaptcha'

import './QuoteForm.component.scss'

const isJson = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch (ex) {
    return false
  }
}

const getInitialView = (quoteType) => {
  switch (quoteType && `${quoteType}`.trim().toLowerCase()) {
    case 'health':
      return '1'
    case 'aca':
      return '4'
    case 'life':
      return '2'
    case 'senior':
      return '3'
    default:
      return '1'
  }
}

const QuoteForm = ({ subsiteData, quoteType }) => {
  const recaptchaRef = React.createRef()

  const howHearOptsRaw = subsiteData?.how_hear_options,
    howHearOptsDefault = '["Search Engine","Referral","Radio Ad","Other"]'

  const [howHearOptions, setHowHearOptions] = useState([])

  const getHowHearOptions = () => {
    if (!howHearOptsRaw || howHearOptsRaw === '[]')
      return JSON.parse(howHearOptsDefault)
    if (isJson(howHearOptsRaw)) return JSON.parse(howHearOptsRaw)
    return JSON.parse(howHearOptsDefault)
  }

  useEffect(() => {
    if (!howHearOptsRaw || howHearOptsRaw === '[]')
      setHowHearOptions(JSON.parse(howHearOptsDefault))
    else if (isJson(howHearOptsRaw))
      setHowHearOptions(JSON.parse(howHearOptsRaw))
    else setHowHearOptions(JSON.parse(howHearOptsDefault))
  }, [howHearOptsRaw, howHearOptsDefault])

  const [formData, setFormData] = useState({
    protection_level: 150,
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    how_hear: '',
    how_hear_detail: '',
    tobacco_user: '',
    age: '',
    captcha: null,
  })
  const [formError, setFormError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasSent, setHasSent] = useState(false)
  const [activeQuoteItem, setActiveQuoteItem] = useState(
    getInitialView(quoteType)
  )

  const onChange = (event) => {
    let name = event.target.name,
      value = event.target.value
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const onCaptchaChange = () =>
    setFormData((prevData) => ({ ...prevData, captcha: !formData.captcha }))

  const validate = async () => {
    const { firstname, lastname, email, mobile, captcha } = formData
    const regPhone = /^(\+?\d{1,2}\s?)?(\(?\d{3}\)?)[\s.-]?\d{3}[\s.-]?\d{4}$/;
    const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

    if (!firstname || !firstname.trim()) {
      setFormError('First name required')
      return false
    }
    if (!lastname || !lastname.trim()) {
      setFormError('Last name required')
      return false
    }
    if (!email || !email.trim()) {
      setFormError('E-mail address required')
      return false
    }

    if (!regEmail.test(email)) {
      setFormError('Invalid E-mail format')
      return false
    }

    if (!mobile || !mobile.trim()) {
      setFormError('Mobile number required')
      return false
    }

    if (!regPhone.test(mobile)) {
      setFormError('Invalid Mobile Number')
      return false
    }

    if (!captcha) {
      setFormError('Captcha required')
      return false
    }

    if (formError) setFormError(null)

    return true
  }

  const createQuoteRequest = async (event) => {
    event.preventDefault()
    const passedValidation = await validate()
    if (!passedValidation || isSubmitting) return false

    const {
        protection_level,
        firstname,
        lastname,
        email,
        mobile,
        how_hear,
        how_hear_detail,
        tobacco_user,
        age,
      } = formData,
      data = {
        protection_level: protection_level,
        q_fname: firstname,
        q_lname: lastname,
        q_mobilephone: mobile,
        q_email: email,
        q_how_hear: how_hear,
        q_how_hear_detail: how_hear_detail,
        q_tobacco: tobacco_user,
        q_age: age,
        q_summary: `<html><body><table>
                            <tr><td>First Name:</td><td>${firstname}</td></tr>
                            <tr><td>Last name:</td><td>${lastname}</td></tr>
                            <tr><td>e-mail:</td><td>${email}</td></tr>
                            <tr><td>Mobile:</td><td>${phoneToView(
                              mobile
                            )}</td></tr>
                            <tr><td>How did you hear about us?:</td><td>${how_hear}</td></tr>
                            <tr><td>If other, how else did you hear about us?:</td><td>${how_hear_detail}</td></tr>
                            <tr><td>Protection Level:</td><td>${protection_level}</td></tr>
                            <tr><td>Tobacco User:</td><td>${tobacco_user}</td></tr>
                            <tr><td>Age:</td><td>${age}</td></tr>
                            <tr><td>Quote Request Type:</td><td>{insuranceType}</td></tr>
                          </table></body></html>`,
        site_id: subsiteData.site_id,
        user_id: subsiteData.user_id,
        req_url: window.location.href,
        ref_url: document.referrer,
      }
    data['insurance_type'] =
      activeQuoteItem === '1'
        ? 'Health'
        : activeQuoteItem === '2'
        ? 'Life'
        : activeQuoteItem === '3'
        ? 'Senior'
        : activeQuoteItem === '4'
        ? 'ACA'
        : 'Health'
    data.q_summary = data.q_summary.replace(
      /{insuranceType}/,
      data['insurance_type']
    )

    try {
      setIsSubmitting(true)
      setHasSent(false)
      await AdminApi.createAgentsContactQuoteRequest(data, 'quote').then(
        (response) => {
          if (response.status === 200) {
            setHasSent(true)
            setFormData({
              protection_level: 150,
              firstname: '',
              lastname: '',
              email: '',
              mobile: '',
              how_hear: '',
              how_hear_detail: '',
              tobacco_user: '',
              age: '',
              captcha: null,
            })
            setFormError(null)
            window.grecaptcha.reset()
          }
          setIsSubmitting(false)
        }
      )
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  const toggleQuoteTabs = (tab) => () => {
    if (activeQuoteItem !== tab) {
      setActiveQuoteItem(`${tab}`)
      setFormData({
        protection_level: 150,
        firstname: '',
        lastname: '',
        email: '',
        mobile: '',
        how_hear: '',
        how_hear_detail: '',
        tobacco_user: '',
        age: '',
        captcha: null,
      })
      setFormError(null)
      if (
        window.grecaptcha.reset &&
        typeof window.grecaptcha.reset === 'function'
      )
        window.grecaptcha.reset()
    }
  }

  return (
    <MDBContainer id="QuoteFormComponent" fluid>
      <MDBRow>
        <MDBCol
          size="12"
          lg="5"
          style={{
            backgroundImage: `url(${quotationFormBgImage})`,
            height: '100%',
          }}
          className="quote-bg"
        ></MDBCol>
        <MDBCol size="12" lg="7" className="quote-forms">
          <MDBCollapse isOpen={hasSent}>
            <MDBAlert color="success">
              <strong>Quote Request Sent!</strong>
            </MDBAlert>
          </MDBCollapse>
          <div
            id="quote-tabs"
            className="tabs-custom tabs-horizontal tabs-line tabs-custom-horizontal"
          >
            <MDBNav className="nav-tabs">
              {!quoteType ||
              `${quoteType}`.toLowerCase().trim() === 'health' ? (
                <MDBNavItem className={activeQuoteItem === '1' ? 'active' : ''}>
                  <MDBNavLink
                    link
                    to="#"
                    active={activeQuoteItem === '1'}
                    onClick={toggleQuoteTabs('1')}
                    role="tab"
                  >
                    HEALTH INSURANCE
                  </MDBNavLink>
                </MDBNavItem>
              ) : (
                <></>
              )}
              {!quoteType || `${quoteType}`.toLowerCase().trim() === 'life' ? (
                <MDBNavItem className={activeQuoteItem === '2' ? 'active' : ''}>
                  <MDBNavLink
                    link
                    to="#"
                    active={activeQuoteItem === '2'}
                    onClick={toggleQuoteTabs('2')}
                    role="tab"
                  >
                    LIFE INSURANCE
                  </MDBNavLink>
                </MDBNavItem>
              ) : (
                <></>
              )}
              {!quoteType ||
              `${quoteType}`.toLowerCase().trim() === 'senior' ? (
                <MDBNavItem className={activeQuoteItem === '3' ? 'active' : ''}>
                  <MDBNavLink
                    link
                    to="#"
                    active={activeQuoteItem === '3'}
                    onClick={toggleQuoteTabs('3')}
                    role="tab"
                  >
                    SENIOR INSURANCE
                  </MDBNavLink>
                </MDBNavItem>
              ) : (
                <></>
              )}
              {!quoteType || `${quoteType}`.toLowerCase().trim() === 'aca' ? (
                <MDBNavItem className={activeQuoteItem === '4' ? 'active' : ''}>
                  <MDBNavLink
                    link
                    to="#"
                    active={activeQuoteItem === '4'}
                    onClick={toggleQuoteTabs('4')}
                    role="tab"
                  >
                    AFFORDABLE CARE ACT
                  </MDBNavLink>
                </MDBNavItem>
              ) : (
                <></>
              )}
            </MDBNav>
            <MDBTabContent
              activeItem={activeQuoteItem}
              className="tab-content text-gray-base"
            >
              <MDBTabPane tabId="1" role="tabpanel" className="fade in">
                <h3 className="font---lato">Health Insurance Quote</h3>
                <form
                  data-form-output="form-output-global"
                  data-form-type="contact"
                  onSubmit={createQuoteRequest}
                  noValidate
                >
                  <div className="form-title-cell">
                    <h6>Personal information:</h6>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="index-business"
                        data-minimum-results-for-search="Infinity"
                        name="tobacco_user"
                        value={subsiteData?.tobacco_user}
                        onChange={onChange}
                        className="form-control select-filter"
                      >
                        <option>Do you smoke?</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                      <label
                        htmlFor="index-business"
                        className="form-label form-label-outside veil"
                      >
                        Do you smoke?
                      </label>
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="how_hear"
                        data-minimum-results-for-search="Infinity"
                        name="how_hear"
                        value={subsiteData?.how_hear}
                        onChange={onChange}
                        className="form-control select-filter mb-2"
                      >
                        <option value="">How did you hear about us?</option>
                        {howHearOptions ? (
                          howHearOptions.map((option) => (
                            <option value={option} key={option}>
                              {option}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </select>
                      <label
                        htmlFor="how_hear"
                        className="form-label form-label-outside veil"
                      >
                        How did you hear about us?
                      </label>
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="age"
                        data-minimum-results-for-search="Infinity"
                        name="age"
                        value={subsiteData?.age}
                        onChange={onChange}
                        className="form-control select-filter"
                      >
                        <option value="">Your age</option>
                        <option value="18 - 20">18 - 20</option>
                        <option value="21 - 25">21 - 25</option>
                        <option value="26 - 36">26 - 36</option>
                        <option value="37 - 48">37 - 48</option>
                        <option value="49 - 55">49 - 55</option>
                        <option value="56 <">Over 55</option>
                      </select>
                      <label
                        htmlFor="age"
                        className="form-label form-label-outside veil"
                      >
                        Your age
                      </label>
                    </div>
                  </div>
                  {(formData.how_hear === 'Other' ||
                    subsiteData.how_hear === 'Other') && (
                    <div className="form-input-cell">
                      <div className="form-group form-group-outside">
                        <input
                          id="how_hear_detail"
                          type="text"
                          name="how_hear_detail"
                          data-constraints="@Required"
                          className="form-control bg-whisper-lighten border-radius-0"
                          value={subsiteData?.how_hear_detail}
                          onChange={onChange}
                          placeholder={'Please tell us how you heard'}
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-title-cell">
                    <h6>Contact details:</h6>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-name"
                        type="text"
                        name="firstname"
                        data-constraints="@Required"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.firstname}
                        onChange={onChange}
                        placeholder={'First Name'}
                      />
                      {/*<label htmlFor="contact-name"
                                                       className="form-label">Name</label>*/}
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-name"
                        type="text"
                        name="lastname"
                        data-constraints="@Required"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.lastname}
                        onChange={onChange}
                        placeholder={'Last Name'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-phone"
                        type="text"
                        name="mobile"
                        data-constraints="@Required @Numeric"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.mobile}
                        autoComplete="off"
                        onChange={onChange}
                        placeholder={'Phone'}
                      />
                      {/*<label htmlFor="contact-phone"
                                                       className="form-label">Phone</label>*/}
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-email"
                        type="text"
                        name="email"
                        autoComplete="off"
                        data-constraints="@Required"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.email}
                        onChange={onChange}
                        placeholder={'E-mail'}
                      />
                      {/*<label htmlFor="contact-email"
                                                       className="form-label">E-mail</label>*/}
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lc_aeIZAAAAACSfGY2OqRXdIzJ1jPOIW__6gq11"
                      onChange={(event) => onCaptchaChange()}
                    />
                  </div>
                  <div className="form-input-submit">
                    <button
                      type="submit"
                      className="btn btn-icon btn-icon-right btn-primary-contrast btn-custom btn-block"
                      disabled={isSubmitting}
                    >
                      <span>
                        {isSubmitting ? 'Submitting...' : 'Get a Quote'}
                      </span>
                      <span className="icon icon-xs-smaller fa-angle-right"></span>
                    </button>
                    <div className="text--red fw--500">{formError}</div>
                  </div>
                </form>
              </MDBTabPane>
              <MDBTabPane tabId="2" role="tabpanel" className="fade in">
                <h3 className="font---lato">Life Insurance Quote</h3>
                <form
                  data-form-output="form-output-global"
                  data-form-type="contact"
                  onSubmit={createQuoteRequest}
                  noValidate
                >
                  <div className="form-title-cell">
                    <h6>Personal information:</h6>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="index-business"
                        data-minimum-results-for-search="Infinity"
                        name="tobacco_user"
                        value={subsiteData?.tobacco_user}
                        onChange={onChange}
                        className="form-control select-filter"
                      >
                        <option>Do you smoke?</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                      <label
                        htmlFor="index-business"
                        className="form-label form-label-outside veil"
                      >
                        Do you smoke?
                      </label>
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="how_hear"
                        data-minimum-results-for-search="Infinity"
                        name="how_hear"
                        value={subsiteData?.how_hear}
                        onChange={onChange}
                        className="form-control select-filter mb-2"
                      >
                        <option value="">How did you hear about us?</option>
                        {howHearOptions ? (
                          howHearOptions.map((option) => (
                            <option value={option} key={option}>
                              {option}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </select>
                      <label
                        htmlFor="how_hear"
                        className="form-label form-label-outside veil"
                      >
                        How did you hear about us?
                      </label>
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="age"
                        data-minimum-results-for-search="Infinity"
                        name="age"
                        value={subsiteData?.age}
                        onChange={onChange}
                        className="form-control select-filter"
                      >
                        <option value="">Your age</option>
                        <option value="18 - 20">18 - 20</option>
                        <option value="21 - 25">21 - 25</option>
                        <option value="26 - 36">26 - 36</option>
                        <option value="37 - 48">37 - 48</option>
                        <option value="49 - 55">49 - 55</option>
                        <option value="56 <">Over 55</option>
                      </select>
                      <label
                        htmlFor="age"
                        className="form-label form-label-outside veil"
                      >
                        Your age
                      </label>
                    </div>
                  </div>
                  <div className="form-title-cell">
                    <h6>Contact details:</h6>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-name"
                        type="text"
                        name="firstname"
                        data-constraints="@Required"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.firstname}
                        onChange={onChange}
                        placeholder={'First Name'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-name"
                        type="text"
                        name="lastname"
                        data-constraints="@Required"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.lastname}
                        onChange={onChange}
                        placeholder={'Last Name'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-phone"
                        type="text"
                        name="mobile"
                        data-constraints="@Required @Numeric"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.mobile}
                        onChange={onChange}
                        placeholder={'Phone'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-email"
                        type="text"
                        name="email"
                        data-constraints="@Required"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.email}
                        autoComplete="off"
                        onChange={onChange}
                        placeholder={'E-mail'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lc_aeIZAAAAACSfGY2OqRXdIzJ1jPOIW__6gq11"
                      onChange={(event) => onCaptchaChange()}
                    />
                  </div>
                  <div className="form-input-submit">
                    <button
                      type="submit"
                      className="btn btn-icon btn-icon-right btn-primary-contrast btn-custom btn-block"
                      disabled={isSubmitting}
                    >
                      <span>
                        {isSubmitting ? 'Submitting...' : 'Get a Quote'}
                      </span>
                      <span className="icon icon-xs-smaller fa-angle-right"></span>
                    </button>
                    <div className="text--red fw--500">{formError}</div>
                  </div>
                </form>
              </MDBTabPane>
              <MDBTabPane tabId="3" role="tabpanel" className="fade in">
                <h3 className="font---lato">Senior Insurance Quote</h3>
                <form
                  data-form-output="form-output-global"
                  data-form-type="contact"
                  onSubmit={createQuoteRequest}
                  noValidate
                >
                  <div className="form-title-cell">
                    <h6>Personal information:</h6>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="index-business"
                        data-minimum-results-for-search="Infinity"
                        name="tobacco_user"
                        value={subsiteData?.tobacco_user}
                        onChange={onChange}
                        className="form-control select-filter"
                      >
                        <option>Do you smoke?</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                      <label
                        htmlFor="index-business"
                        className="form-label form-label-outside veil"
                      >
                        Do you smoke?
                      </label>
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="how_hear"
                        data-minimum-results-for-search="Infinity"
                        name="how_hear"
                        value={subsiteData?.how_hear}
                        onChange={onChange}
                        className="form-control select-filter mb-2"
                      >
                        <option value="">How did you hear about us?</option>
                        {getHowHearOptions().map((option) => (
                          <option value={option} key={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <label
                        htmlFor="how_hear"
                        className="form-label form-label-outside veil"
                      >
                        How did you hear about us?
                      </label>
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="age"
                        data-minimum-results-for-search="Infinity"
                        name="age"
                        value={subsiteData?.age}
                        onChange={onChange}
                        className="form-control select-filter"
                      >
                        <option value="">Your age</option>
                        <option value="18 - 20">18 - 20</option>
                        <option value="21 - 25">21 - 25</option>
                        <option value="26 - 36">26 - 36</option>
                        <option value="37 - 48">37 - 48</option>
                        <option value="49 - 55">49 - 55</option>
                        <option value="56 <">Over 55</option>
                      </select>
                      <label
                        htmlFor="age"
                        className="form-label form-label-outside veil"
                      >
                        Your age
                      </label>
                    </div>
                  </div>
                  <div className="form-title-cell">
                    <h6>Contact details:</h6>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-name"
                        type="text"
                        name="firstname"
                        data-constraints="@Required"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.firstname}
                        onChange={onChange}
                        placeholder={'First Name'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-name"
                        type="text"
                        name="lastname"
                        data-constraints="@Required"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.lastname}
                        onChange={onChange}
                        placeholder={'Last Name'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-phone"
                        type="text"
                        name="mobile"
                        data-constraints="@Required @Numeric"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.mobile}
                        onChange={onChange}
                        placeholder={'Phone'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-email"
                        type="text"
                        name="email"
                        data-constraints="@Required"
                        autoComplete="off"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.email}
                        onChange={onChange}
                        placeholder={'E-mail'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lc_aeIZAAAAACSfGY2OqRXdIzJ1jPOIW__6gq11"
                      onChange={(event) => onCaptchaChange()}
                    />
                  </div>
                  <div className="form-input-submit">
                    <button
                      type="submit"
                      className="btn btn-icon btn-icon-right btn-primary-contrast btn-custom btn-block"
                      disabled={isSubmitting}
                    >
                      <span>
                        {isSubmitting ? 'Submitting...' : 'Get a Quote'}
                      </span>
                      <span className="icon icon-xs-smaller fa-angle-right"></span>
                    </button>
                    <div className="text--red fw--500">{formError}</div>
                  </div>
                </form>
              </MDBTabPane>
              <MDBTabPane tabId="4" role="tabpanel" className="fade in">
                <h3 className="font---lato">Affordable Care Act Quote</h3>
                <form
                  data-form-output="form-output-global"
                  data-form-type="contact"
                  onSubmit={createQuoteRequest}
                  noValidate
                >
                  <div className="form-title-cell">
                    <h6>Personal information:</h6>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="index-business"
                        data-minimum-results-for-search="Infinity"
                        name="tobacco_user"
                        value={subsiteData?.tobacco_user}
                        onChange={onChange}
                        className="form-control select-filter"
                      >
                        <option>Do you smoke?</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                      <label
                        htmlFor="index-business"
                        className="form-label form-label-outside veil"
                      >
                        Do you smoke?
                      </label>
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="how_hear"
                        data-minimum-results-for-search="Infinity"
                        name="how_hear"
                        value={subsiteData?.how_hear}
                        onChange={onChange}
                        className="form-control select-filter mb-2"
                      >
                        <option value="">How did you hear about us?</option>
                        {getHowHearOptions().map((option) => (
                          <option value={option} key={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <label
                        htmlFor="how_hear"
                        className="form-label form-label-outside veil"
                      >
                        How did you hear about us?
                      </label>
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <select
                        id="age"
                        data-minimum-results-for-search="Infinity"
                        name="age"
                        value={subsiteData?.age}
                        onChange={onChange}
                        className="form-control select-filter"
                      >
                        <option value="">Your age</option>
                        <option value="18 - 20">18 - 20</option>
                        <option value="21 - 25">21 - 25</option>
                        <option value="26 - 36">26 - 36</option>
                        <option value="37 - 48">37 - 48</option>
                        <option value="49 - 55">49 - 55</option>
                        <option value="56 <">Over 55</option>
                      </select>
                      <label
                        htmlFor="age"
                        className="form-label form-label-outside veil"
                      >
                        Your age
                      </label>
                    </div>
                  </div>
                  <div className="form-title-cell">
                    <h6>Contact details:</h6>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-name"
                        type="text"
                        name="firstname"
                        data-constraints="@Required"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.firstname}
                        onChange={onChange}
                        placeholder={'First Name'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-name"
                        type="text"
                        name="lastname"
                        data-constraints="@Required"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.lastname}
                        onChange={onChange}
                        placeholder={'Last Name'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-phone"
                        type="text"
                        name="mobile"
                        data-constraints="@Required @Numeric"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.mobile}
                        onChange={onChange}
                        placeholder={'Phone'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <div className="form-group form-group-outside">
                      <input
                        id="contact-email"
                        type="text"
                        name="email"
                        data-constraints="@Required"
                        autoComplete="off"
                        className="form-control bg-whisper-lighten border-radius-0"
                        value={subsiteData?.email}
                        onChange={onChange}
                        placeholder={'E-mail'}
                      />
                    </div>
                  </div>
                  <div className="form-input-cell">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lc_aeIZAAAAACSfGY2OqRXdIzJ1jPOIW__6gq11"
                      onChange={(event) => onCaptchaChange()}
                    />
                  </div>
                  <div className="form-input-submit">
                    <button
                      type="submit"
                      className="btn btn-icon btn-icon-right btn-primary-contrast btn-custom btn-block"
                      disabled={isSubmitting}
                    >
                      <span>
                        {isSubmitting ? 'Submitting...' : 'Get a Quote'}
                      </span>
                      <span className="icon icon-xs-smaller fa-angle-right"></span>
                    </button>
                    <div className="text--red fw--500">{formError}</div>
                  </div>
                </form>
              </MDBTabPane>
            </MDBTabContent>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default QuoteForm
