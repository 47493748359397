import React from 'react'
import ModalsController from './../shared/Modals.controller'
import LoadingSpinner from './../../shared/LoadingSpinner.component'
import { MDBModalHeader, MDBModalBody, MDBModal } from 'mdbreact'
import CareerRequestForm from './../../newhome/RequestForm.career'

import './CareerRequestModal.component.scss'

const MODAL_ID = 'modals.career-request',
  MODAL_BODY_CLASS = 'career-modal-open'

class CareerRequestModal extends React.Component {
  state = {
    isLoading: false,
    isOpen: false,
  }

  _ = null

  _open = () => this.setState({ isOpen: true })

  _isOpen = () => this.state.isOpen === true

  _isAutoOpen = () => this._.params(MODAL_ID, 'autoOpen') !== false

  // _toggle = () => {
  //   const isOpen = !this.state.isOpen
  //   try {
  //     if (isOpen) this._.checkCanOpen(MODAL_ID)
  //     this._[isOpen ? 'open' : 'close'](MODAL_ID)
  //   } catch (ex) {
  //     return false
  //   }
  //   this.setState({ isOpen })
  // }

  _close = () => {
    if (typeof this.props?.onClose === 'function') this.props.onClose()
  }

  componentDidMount() {
    this._ = ModalsController.init(MODAL_ID, {
      autoOpen:
        !isNaN(this.props?.autoOpen) && this.props.autoOpen > 0
          ? parseInt(this.props.autoOpen)
          : false,
      bodyClass: MODAL_BODY_CLASS,
    })
  }

  // componentDidUpdate() {
  //   if (!this._isOpen() && this.props.isOpen && this._ && !this._isAutoOpen())
  //     this._toggle()
  // }

  componentWillUnmount() {}

  render() {
    return (
      <MDBModal
        size="lg"
        isOpen={this.props.isOpen}
        toggle={this._close}
        id="CareerRequestModalComponent"
        disablebackdrop={"true"}
      >
        <MDBModalHeader toggle={this._close}>Get More Info!</MDBModalHeader>
        <MDBModalBody className={this.state.isLoading ? 'is-loading' : ''}>
          {this.state.isLoading ? (
            <div className="loading-wrapper">
              <LoadingSpinner size="md" isActive={true} />
            </div>
          ) : (
            <>
              <div>
                <CareerRequestForm onSuccess={() => this._close()} />
              </div>
            </>
          )}
        </MDBModalBody>
      </MDBModal>
    )
  }
}

export default CareerRequestModal
