import { makeAutoObservable, toJS } from 'mobx'
import CarrierFactory from './../../shared/factories/carrier.factory'
import CarrierPriorityFactory from './../../shared/factories/carrier-priority.factory'
import CarrierService from './../../shared/services/Carrier.service'
import CarrierFmoService from './../../shared/services/CarrierFmo.service'
import CarrierContactService from './../../shared/services/CarrierContact.service'
import CarrierContractService from './../../shared/services/CarrierContract.service'
import CarrierPriorityService from './../../shared/services/CarrierPriority.service'
import CarrierCoverageService from './../../shared/services/CarrierCoverage.service'
import DivisionCarrierService from './../../shared/services/DivisionCarrier.service'
import CoverageService from './../../shared/services/Coverage.service'
import DivisionService from './../../shared/services/Division.service'
import CommissionLevelService from './../../shared/services/CommissionLevel.service'
import CarrierCommissionLevelService from './../../shared/services/CarrierCommissionLevel.service'

const sortCarriers = (CarA, CarB) => {
  let A = `${CarA && CarA?.get('c_name') ? CarA.get('c_name') : ''}`
      .trim()
      .toLowerCase(),
    B = `${CarB && CarB?.get('c_name') ? CarB.get('c_name') : ''}`
      .trim()
      .toLowerCase()

  return `${A}`.toLowerCase() > `${B}`.toLowerCase()
    ? 1
    : `${A}`.toLowerCase() < `${B}`.toLowerCase()
    ? -1
    : 0
}

class CarrierMgmtStore {
  constructor() {
    makeAutoObservable(this)
  }

  // singular
  carrier = null
  fmo = null
  contact = null
  contract = null
  priority = null
  note = null
  siblings = []
  carrierCommLevels = {}

  // lists
  _carriers = []
  _ormCarriers = []
  fmos = []
  contacts = []
  priorities = []
  coverages = []
  divisions = []
  commLevels = {}
  availSiblings = []
  maps = {
    coverages: {},
    divisions: {},
  }
  source = {
    coverages: [],
    divisions: [],
  }

  // states
  _view = 'active'
  _loading = {
    'fetch.carriers': false,
    'fetch.carrier': false,
    'fetch.contacts': false,
    'update.contact': false,
    'fetch.siblings': false,
    'update.sibling': false,
    'fetch.fmos': false,
    'fetch.fmo': false,
    'update.carrier': false,
    'fetch.contract': false,
    'update.contract': false,
    'fetch.coverages': false,
    'update.coverages': false,
    'fetch.divisions': false,
    'update.divisions': false,
    'update.fmo': false,
    'fetch.comm-codes': false,
    'update.comm-codes': false,
  }

  getCarriers = (useOrm) =>
    useOrm ? this._ormCarriers : toJS(this._carriers) || []

  getCarrier = (useOrm) =>
    useOrm ? this._ormCarrier : toJS(this.carrier) || {}

  fetchCarriers = async () => {
    this._loading['fetch.carriers'] = true

    let carriers = []
    try {
      carriers = await CarrierService.search({ pagination: false })
    } catch (ex) {}

    if (carriers && Array.isArray(carriers?.models))
      this._carriers = carriers.models
    else if (carriers && Array.isArray(carriers)) this._carriers = carriers

    let carrierIds = (carriers = this.getCarriers()).map(
        (carrier) => carrier.id
      ),
      priorities = []
    if (carrierIds && carrierIds.length > 0)
      priorities =
        (await CarrierPriorityFactory.findAllByCarrierId(carrierIds)) || []

    this._ormCarriers = (
      (await Promise.all(
        carriers.map((carrier) => CarrierFactory.create(carrier))
      )) || []
    )
      .map((Carrier) => {
        Carrier.priorities = priorities
          .filter(
            (Priority) =>
              parseInt(Priority.get('carrier_id')) === parseInt(Carrier.id())
          )
          .map((Priority) => Priority.export())
        return Carrier
      })
      .sort(sortCarriers)

    this._loading['fetch.carriers'] = false
    return carriers
  }

  fetchCarrier = async (carrierId) => {
    this._loading['fetch.carrier'] = true

    this.siblings = []
    this.availSiblings = []

    if (!isNaN(carrierId)) {
      let carrier = {}
      try {
        carrier = await CarrierService.get(carrierId)
      } catch (ex) {}

      if (carrier && Object.keys(carrier).length) {
        this.carrier = carrier
        if (
          !this.carrier.visibility ||
          Object.keys(this.carrier.visibility).length === 0
        ) {
          this.carrier.visibility = {
            tracker: {
              enable: false,
            },
            // onboarding: {
            //     enable: false,
            //     csr_agent: false,
            //     sig_agent: false,
            //     affiliate_agent: false,
            //     associate_agent: false
            // },
            // contracting: {
            //     enable: false,
            //     csr_agent: false,
            //     sig_agent: false,
            //     affiliate_agent: false,
            //     associate_agent: false
            // }
          }
        }
        if (carrier && carrier?.id) {
          this.fetchCarrierCommCodes()
          if (carrier?.fmo_id) await this.fetchFMO(carrier?.fmo_id)
        }
      } else {
        this.carrier = false
      }

      this._loading['fetch.carrier'] = false

      // fetch siblings.
      this.fetchSiblings(this.getCarrier())

      return this.getCarrier()
    } else if (carrierId && `${carrierId}`.trim().toLowerCase() === 'new')
      this.carrier = {
        c_active: 1,
        visibility: {
          tracker: {
            enable: false,
          },
          onboarding: {
            enable: false,
            csr_agent: false,
            sig_agent: false,
            affiliate_agent: false,
            associate_agent: false,
            upline_ids: [],
          },
          contracting: {
            enable: false,
            csr_agent: false,
            sig_agent: false,
            affiliate_agent: false,
            associate_agent: false,
            upline_ids: [],
          },
        },
        can_email_attachment: 0,
      }

    this._loading['fetch.carrier'] = false

    return this.getCarrier()
  }

  fetchCarrierCommCodes = async () => {
    this._loading['fetch.comm-codes'] = true

    const carrierId = parseInt(this.carrier.id),
      fetchRemote = async () => {
        try {
          return await Promise.all([
            CommissionLevelService.search({ pagination: false }),
            CarrierCommissionLevelService.search({
              search: { carrier_id: carrierId },
              pagination: false,
            }),
          ])
        } catch (ex) {
          return false
        }
      }

    if (!isNaN(carrierId)) {
      let carrierCommLevels = [],
        commLevels = [],
        Responses = await fetchRemote()

      if (Responses && Array.isArray(Responses)) {
        commLevels = Responses.shift()?.models || []
        carrierCommLevels = Responses.shift()?.models || []
      }

      commLevels
        .filter((commLevel) => /^USABG/.test(`${commLevel?.com_code}`))
        .forEach((commLevel) => {
          this.carrierCommLevels[commLevel.com_code] = carrierCommLevels
            .filter(
              (carrierCommLevel) =>
                carrierCommLevel.commission_id &&
                `${carrierCommLevel.commission_id}` === `${commLevel.id}`
            )
            .shift() || {
            commission_id: commLevel.id,
            carrier_id: this.carrier.id,
            override: '',
          }
          this.commLevels[commLevel.com_code] = commLevel
        })
    }

    this._loading['fetch.comm-codes'] = false
  }

  saveCarrierCommCodes = async () => {
    let results,
      carrierCommLevels = Object.keys(toJS(this.carrierCommLevels))
        .filter(
          (commCode) => this.carrierCommLevels[commCode]?.modified === true
        )
        .map((commCode) => toJS(this.carrierCommLevels[commCode]))
        .filter(
          (commLevel) => !!commLevel?.carrier_id && !!commLevel?.commission_id
        )
        .map((commLevel) => {
          return {
            override: commLevel?.override || '',
            carrier_id: commLevel.carrier_id,
            commission_id: commLevel.commission_id,
            id: commLevel?.id || false,
          }
        })

    if (carrierCommLevels && carrierCommLevels.length > 0) {
      this._loading['update.comm-codes'] = true

      try {
        results = await Promise.all(
          carrierCommLevels.map((carrierCommLevel) => {
            carrierCommLevel = JSON.parse(JSON.stringify(carrierCommLevel))
            if (carrierCommLevel.id)
              return CarrierCommissionLevelService.update(
                carrierCommLevel.id,
                carrierCommLevel
              )
            delete carrierCommLevel.id
            return CarrierCommissionLevelService.store(carrierCommLevel)
          })
        )
      } catch (ex) {
        console.error(`Failed to save carrier commission codes. ex: ${ex}`)
      }

      this._loading['update.comm-codes'] = false

      if (Array.isArray(results)) {
        results.forEach(
          (result, idx) =>
            (carrierCommLevels[idx] = { ...carrierCommLevels[idx], ...result })
        )
        return true
      }
    }

    return false
  }

  saveCarrier = async (override) => {
    let changes = {},
      exists = !isNaN(this.carrier?.id) && parseInt(this.carrier?.id) > 0,
      permitted = [
        'c_name',
        'c_description',
        'c_term_length',
        'c_term_length_min',
        'c_term_length_max',
        'c_active',
        'c_website',
        'visibility',
        'can_email_attachment',
        'requires_med_adv',
        'requires_ffm',
        'requires_aml',
        'fmo_id',
        'is_automated',
        'contract_email',
      ]
    permitted
      .filter((fld) => this.carrier.hasOwnProperty(fld))
      .forEach(
        (fld) =>
          (changes[fld] =
            fld !== 'visibility'
              ? this.carrier[fld]
              : JSON.stringify(this.carrier[fld]))
      )
    changes =
      override && typeof override === 'object'
        ? { ...changes, ...override }
        : changes

    if (!Object.keys(changes).length) return

    this._loading['update.carrier'] = true

    let results
    try {
      results = exists
        ? await CarrierService.update(
            (override && override?.id) || this.carrier.id,
            changes
          )
        : await CarrierService.store(changes)
    } catch (ex) {
      console.error('Failed to save carrier record. ', ex)
    }

    this._loading['update.carrier'] = false

    if (results && results?.id) {
      this._carriers = exists
        ? this._carriers.map((carrier) =>
            parseInt(carrier.id) === parseInt(results.id)
              ? { ...carrier, ...changes, ...results }
              : carrier
          )
        : this._carriers.concat([{ ...changes, ...results }])
      return true
    }

    return false
  }

  saveCarrierInstructions = async (ci, pci) => {
    let carrierId = parseInt(this.carrier?.id)
    if (!carrierId || isNaN(carrierId)) return false

    ci = ci || ''
    pci = pci || ''

    this._loading['update.carrier'] = true

    let results
    try {
      results = await CarrierService.update(carrierId, {
        contracting_instructions: ci,
        post_contracting_instructions: pci,
      })
    } catch (ex) {
      console.error('Failed to save carrier contracting instructions. ', ex)
      this._loading['update.carrier'] = false
      throw ex
    }

    this._loading['update.carrier'] = false

    if (results && results?.id) {
      this.carrier.contracting_instructions = ci
      this.carrier.post_contracting_instructions = pci
      this._carriers = this.getCarriers().map((carrier) =>
        parseInt(carrier.id) === parseInt(results.id)
          ? {
              ...carrier,
              ...{
                contracting_instructions: ci,
                post_contracting_instructions: pci,
              },
              ...results,
            }
          : carrier
      )

      return true
    }

    return false
  }

  getFmos = () => toJS(this.fmos) || []

  getFmo = () => toJS(this.fmo) || {}

  fetchFMOs = async () => {
    this._loading['fetch.fmos'] = true

    let fmos = []
    try {
      fmos = await CarrierFmoService.search({ pagination: false })
    } catch (ex) {}

    if (fmos && Array.isArray(fmos?.models)) this.fmos = fmos.models
    else if (fmos && Array.isArray(fmos)) this.fmos = fmos

    this._loading['fetch.fmos'] = false

    return this.getFmos()
  }

  fetchFMO = async (fmoId) => {
    this._loading['fetch.fmo'] = true

    if (!isNaN(fmoId)) {
      let fmo = {}
      try {
        fmo = await CarrierFmoService.get(fmoId)
      } catch (ex) {}

      this.fmo = fmo && Object.keys(fmo).length ? fmo : null
      this._loading['fetch.fmo'] = false

      return this.getFmo()
    }

    this._loading['fetch.fmo'] = false

    return this.getFmo()
  }

  saveFMO = async (fmo) => {
    this._loading['update.fmo'] = true

    let exists = !!(fmo && fmo?.id > 0),
      results

    try {
      results = exists
        ? await CarrierFmoService.update(fmo.id, fmo)
        : await CarrierFmoService.store(fmo)
    } catch (ex) {
      console.error('Failed to save fmo record. ', ex)
    }

    this._loading['update.fmo'] = false

    if (results && results?.id) {
      this.fmos = exists
        ? this.fmos.map((f) =>
            parseInt(f.id) === parseInt(results.id)
              ? { ...f, ...fmo, ...results }
              : f
          )
        : this.fmos.concat([{ ...fmo, ...results }])
      return true
    }

    return false
  }

  fetchSiblings = async () => {
    this._loading['fetch.siblings'] = true

    let siblingIds = []
    try {
      siblingIds = this.getCarrier()?.sibling_ids
      if (siblingIds && typeof siblingIds === 'string')
        siblingIds = JSON.parse(siblingIds)
    } catch (ex) {}
    if (Array.isArray(siblingIds) && siblingIds.length > 0) {
      let siblings = []
      try {
        siblings =
          (
            await CarrierService.search({
              search: { id: siblingIds, c_active: 1 },
              pagination: false,
            })
          )?.models || []
      } catch (ex) {}

      this.siblings = (siblings || []).sort((a, b) =>
        `${a.c_name}`.localeCompare(`${b.c_name}`, 'en', { numeric: true })
      )
    }

    this._loading['fetch.siblings'] = false

    return toJS(this.siblings)
  }

  fetchAvailSiblings = async () => {
    this._loading['fetch.avail-siblings'] = true

    let siblingIds = []
    try {
      siblingIds = this.getCarrier()?.sibling_ids
      if (siblingIds && typeof siblingIds === 'string')
        siblingIds = JSON.parse(siblingIds)
    } catch (ex) {
      siblingIds = []
    }

    let availSiblings = []
    try {
      availSiblings =
        (
          await CarrierService.search({
            search: { c_active: 1 },
            pagination: false,
          })
        )?.models || []
    } catch (ex) {}

    this.availSiblings = availSiblings
      .filter(
        (as) =>
          (!siblingIds ||
            !siblingIds.length ||
            siblingIds.indexOf(parseInt(as.id)) < 0) &&
          parseInt(as.id) !== parseInt(this.carrier.id)
      )
      .sort((a, b) =>
        `${a.c_name}`.localeCompare(`${b.c_name}`, 'en', { numeric: true })
      )

    this._loading['fetch.avail-siblings'] = false

    return toJS(this.availSiblings)
  }

  linkSiblings = async (siblingIds) => {
    this._loading['update.sibling'] = true

    siblingIds = siblingIds.map((s) => parseInt(s))
    let Siblings = []
      .concat(
        this.siblings.filter((s) => siblingIds.indexOf(parseInt(s.id)) > -1)
      )
      .concat(
        this.availSiblings.filter(
          (s) => siblingIds.indexOf(parseInt(s.id)) > -1
        )
      )
    siblingIds = Siblings.map((s) => parseInt(s.id))

    try {
      await Promise.all(
        Siblings.map((Sibling) =>
          CarrierService.update(Sibling.id, {
            sibling_ids: JSON.stringify(
              siblingIds.filter((s) => s !== Sibling.id)
            ),
          })
        )
      )
    } catch (ex) {
      console.log(`Failed to link carrier siblings. ${ex}`)
    }

    this.carrier.sibling_ids = siblingIds

    await Promise.all([this.fetchSiblings(), this.fetchAvailSiblings()])

    this._loading['update.sibling'] = false

    return
  }

  unlinkSibling = async (siblingId) => {
    this._loading['update.sibling'] = true

    // 1. move targeted sibling from current carrier siblings array
    // over to available siblings array.
    // & remove all siblings from target sibling.
    this.availSiblings.push(
      this.siblings.filter((s) => parseInt(s.id) === parseInt(siblingId))
    )
    this.siblings = this.siblings.filter(
      (s) => parseInt(s.id) !== parseInt(siblingId)
    )
    let updPromise = CarrierService.update(siblingId, { sibling_ids: null })

    // 2. remove target sibling ID from existing siblings.
    await Promise.all(
      [updPromise].concat(
        [this.carrier].concat(this.siblings).map((s) => {
          s.sibling_ids = Array.isArray(s.sibling_ids)
            ? s.sibling_ids
            : s.sibling_ids
            ? JSON.parse(s.sibling_ids)
            : []
          s.sibling_ids = s.sibling_ids.filter(
            (id) => parseInt(id) !== parseInt(siblingId)
          )
          return CarrierService.update(s.id, {
            sibling_ids: JSON.stringify(s.sibling_ids),
          })
        })
      )
    )

    this._loading['update.sibling'] = false

    return
  }

  fetchContacts = async (carrierId) => {
    this._loading['fetch.contacts'] = true

    let contacts = [],
      search = {}

    if (parseInt(carrierId) > 0) search.carrier_id = parseInt(carrierId)

    try {
      contacts = await CarrierContactService.search({
        search,
        pagination: false,
      })
    } catch (ex) {}

    if (contacts && Array.isArray(contacts?.models))
      this.contacts = contacts.models
    else if (contacts && Array.isArray(contacts)) this.contacts = contacts

    this._loading['fetch.contacts'] = false

    return toJS(this.contacts)
  }

  saveContact = async (override) => {
    let changes = {},
      id = null
    changes =
      override && typeof override === 'object'
        ? { ...changes, ...override }
        : changes

    if (changes?.id) {
      id = changes.id
      delete changes.id
    }

    if (!Object.keys(changes).length || !changes?.name) return

    if (!id && !changes?.carrier_id)
      changes.carrier_id = parseInt(this.carrier.id)

    let results
    try {
      results =
        parseInt(id) > 0
          ? await CarrierContactService.update(id, changes)
          : await CarrierContactService.store(changes)
    } catch (ex) {
      console.error('Failed to save contact record. ', ex)
    }

    if (results && results?.id) {
      this.contacts =
        parseInt(id) > 0
          ? this.contacts.map((contact) =>
              parseInt(contact.id) === parseInt(results.id)
                ? { ...contact, ...changes, ...results }
                : contact
            )
          : this.contacts.concat([{ ...changes, ...results }])
      return results
    }

    return false
  }

  deleteContact = async (contactId) => {
    try {
      CarrierContactService.delete(contactId)
      this.contacts = this.contacts.filter(
        (contact) => parseInt(contact.id) !== parseInt(contactId)
      )
      return true
    } catch (ex) {
      return false
    }
  }

  fetchContracts = async (carrierId) => {
    this._loading['fetch.contract'] = true

    let contract = {},
      search = { carrier_id: parseInt(carrierId) }

    if (isNaN(carrierId) || !(parseInt(carrierId) > 0)) return

    try {
      contract = await CarrierContractService.search({
        search,
        pagination: false,
      })
    } catch (ex) {}

    if (contract && Array.isArray(contract?.models))
      contract = contract.models.shift() || {}
    else if (contract && Array.isArray(contract)) contract = contract || {}

    this.contract = contract && typeof contract === 'object' ? contract : {}

    this._loading['fetch.contracts'] = false

    return toJS(this.contract)
  }

  saveContract = async () => {
    let payload = { ...this.contract },
      id = payload && payload?.id ? parseInt(payload.id) : null

    if (payload?.id) {
      id = payload.id
      delete payload.id
    }

    this._loading['update.contract'] = true

    if (!payload?.id && !payload?.carrier_id)
      payload.carrier_id = parseInt(this.carrier.id)

    let results
    try {
      results =
        parseInt(id) > 0
          ? await CarrierContractService.update(id, payload)
          : await CarrierContractService.store(payload)
    } catch (ex) {
      console.error('Failed to save contract record. ', ex)
    }

    if (results && results?.id) {
      this.priorities =
        parseInt(id) > 0
          ? this.priorities.map((contract) =>
              parseInt(contract.id) === parseInt(results.id)
                ? { ...contract, ...payload, ...results }
                : contract
            )
          : this.priorities.concat([{ ...payload, ...results }])

      this._loading['update.contract'] = false
      return results
    }

    this._loading['update.contract'] = false
    return false
  }

  fetchPriorities = async (carrierId) => {
    this._loading['fetch.priorities'] = true

    let priorities = [],
      search = { carrier_id: parseInt(carrierId) }

    if (isNaN(carrierId) || !(parseInt(carrierId) > 0)) return

    try {
      priorities = await CarrierPriorityService.search({
        search,
        pagination: false,
      })
    } catch (ex) {}

    if (priorities && Array.isArray(priorities?.models))
      this.priorities = priorities.models
    else if (priorities && Array.isArray(priorities))
      this.priorities = priorities

    this._loading['fetch.priorities'] = false

    return toJS(this.priorities)
  }

  deletePriority = async (priorityId) => {
    try {
      CarrierPriorityService.delete(priorityId)
      this.priorities = this.priorities.filter(
        (priority) => parseInt(priority.id) !== parseInt(priorityId)
      )
      return true
    } catch (ex) {
      return false
    }
  }

  savePriority = async () => {
    let payload = { ...this.priority },
      id = payload && payload?.id ? parseInt(payload.id) : null

    if (!payload?.spec) return

    if (payload?.id) {
      id = payload.id
      delete payload.id
    }

    this._loading['update.priority'] = true

    if (!payload?.id && !payload?.carrier_id)
      payload.carrier_id = parseInt(this.carrier.id)

    Object.keys(payload)
      .filter((k) =>
        /^is_(affiliate|associate|signature)_(core|preferred)$/.test(k)
      )
      .forEach((k) => (payload[k] = payload[k] ? 1 : 0))

    let results
    try {
      results =
        parseInt(id) > 0
          ? await CarrierPriorityService.update(id, payload)
          : await CarrierPriorityService.store(payload)
    } catch (ex) {
      console.error('Failed to save priority record. ', ex)
    }

    if (results && results?.id) {
      this.priorities =
        parseInt(id) > 0
          ? this.priorities.map((priority) =>
              parseInt(priority.id) === parseInt(results.id)
                ? { ...priority, ...payload, ...results }
                : priority
            )
          : this.priorities.concat([{ ...payload, ...results }])

      this._loading['update.priority'] = false
      return results
    }

    this._loading['update.priority'] = false
    return false
  }

  getNotes() {
    if (!this.carrier) return []
    this.carrier.notes =
      typeof this.carrier.notes === 'string'
        ? JSON.parse(this.carrier.notes)
        : this.carrier.notes
    return this.carrier.notes
  }

  deleteNote = async (noteId) => {
    let notes = []
    this.getNotes()
    if (noteId && this.carrier.notes && this.carrier.notes.length) {
      notes = this.carrier.notes.filter((note) => note.id !== noteId)
    }
    await this.saveCarrier({ notes })
    this.carrier.notes = notes
  }

  saveNote = async () => {
    let notes = []
    this.getNotes()
    if (this.carrier.notes && this.carrier.notes.length) {
      if (!this.note.id) {
        notes = [...this.carrier.notes, this.note]
      } else {
        notes = this.carrier.notes.map((note) =>
          note.id === this.note.id ? this.note : note
        )
      }
    } else {
      notes = [this.note]
    }
    await this.saveCarrier({ notes })
    if (!this.note.id) {
      this.note = null
      await this.fetchCarrier(this.carrier.id)
    } else {
      this.note = null
    }
  }

  fetchCoverages = async (carrierId) => {
    this._loading['fetch.coverages'] = true

    let source = [],
      coverages = [],
      search = { carrier_id: parseInt(carrierId) }

    if (isNaN(carrierId) || !(parseInt(carrierId) > 0)) return

    try {
      source = await CoverageService.search({ pagination: false })
      coverages = await CarrierCoverageService.search({
        search,
        pagination: false,
      })
    } catch (ex) {}

    this.maps.coverages = {}
    ;(source && Array.isArray(source?.models) ? source.models : []).forEach(
      (cov) => (this.maps.coverages[cov.id] = cov)
    )
    this.source.coverages = Object.keys(this.maps.coverages)
      .map((covId) => this.maps.coverages[covId])
      .sort((a, b) => {
        a = `${a.coverage_name}`.trim().charAt(0).toLowerCase()
        b = `${b.coverage_name}`.trim().charAt(0).toLowerCase()
        return a > b ? 1 : a < b ? -1 : 0
      })

    if (coverages && Array.isArray(coverages?.models))
      this.coverages = coverages.models
    else if (coverages && Array.isArray(coverages)) this.coverages = coverages

    this._loading['fetch.coverages'] = false

    return toJS(this.coverages)
  }

  toggleCoverage = async (coverageId) => {
    let CarrCov = this.coverages
        .filter((cov) => parseInt(cov.coverage_id) === parseInt(coverageId))
        .shift(),
      remaining = this.coverages.filter(
        (cov) => parseInt(cov.coverage_id) !== parseInt(coverageId)
      )

    this._loading['update.coverages'] = true

    if (CarrCov) {
      CarrierCoverageService.delete(CarrCov.carriercoverage_id)
      this.coverages = remaining
    } else {
      let payload = { carrier_id: this.carrier.id, coverage_id: coverageId },
        result = await CarrierCoverageService.store(payload)
      if (result && result?.id)
        this.coverages = this.coverages.concat([
          { ...payload, carriercoverage_id: result.id },
        ])
    }

    this._loading['update.coverages'] = false
  }

  getDivision = (divisionId) =>
    this.maps.divisions[divisionId]
      ? this.maps.divisions[divisionId]
      : undefined

  getDivisionCarrier = (divisionId) =>
    toJS(this.divisions)
      .filter((div) => `${div.division_id}` === `${divisionId}`)
      .shift()

  isDivisionCarrierActive = (divisionId) =>
    (this.divisions || []).filter(
      (div) =>
        parseInt(div.dc_active) === 1 &&
        `${div.division_id}` === `${divisionId}`
    ).length > 0

  fetchDivisions = async (carrierId) => {
    this._loading['fetch.divisions'] = true

    let source = [],
      divisions = [],
      search = { carrier_id: parseInt(carrierId) }

    if (isNaN(carrierId) || !(parseInt(carrierId) > 0)) return

    try {
      source = await DivisionService.search({ pagination: false })
      divisions = await DivisionCarrierService.search({
        search,
        pagination: false,
      })
    } catch (ex) {
      console.log('ERROR: ', ex)
    }

    this.maps.divisions = {}
    ;(source && Array.isArray(source?.models) ? source.models : []).forEach(
      (cov) => (this.maps.divisions[cov.id] = cov)
    )
    this.source.divisions = Object.keys(this.maps.divisions)
      .map((divId) => this.maps.divisions[divId])
      .sort((a, b) => {
        a = `${a.d_name}`.trim().charAt(0).toLowerCase()
        b = `${b.d_name}`.trim().charAt(0).toLowerCase()
        return a > b ? 1 : a < b ? -1 : 0
      })

    if (divisions && Array.isArray(divisions?.models))
      this.divisions = divisions.models
    else if (divisions && Array.isArray(divisions)) this.divisions = divisions

    this._loading['fetch.divisions'] = false

    return toJS(this.divisions)
  }

  toggleDivision = async (divisionId) => {
    let DivCarr = this.getDivisionCarrier(divisionId)
    this._loading['update.divisions'] = true

    if (DivCarr) {
      if (parseInt(DivCarr?.dc_active) === 1) {
        DivisionCarrierService.update(DivCarr.divisioncarrier_id, {
          dc_active: 0,
        })
        this.divisions = this.divisions.map((div) =>
          parseInt(div.divisioncarrier_id) ===
          parseInt(DivCarr.divisioncarrier_id)
            ? { ...div, dc_active: 0 }
            : div
        )
      } else {
        DivisionCarrierService.update(DivCarr.divisioncarrier_id, {
          dc_active: 1,
        })
        this.divisions = this.divisions.map((div) =>
          parseInt(div.divisioncarrier_id) ===
          parseInt(DivCarr.divisioncarrier_id)
            ? { ...div, dc_active: 1 }
            : div
        )
      }
    } else {
      let payload = {
          carrier_id: this.carrier.id,
          division_id: divisionId,
          dc_active: 1,
          dc_recommended: 0,
        },
        result = await DivisionCarrierService.store(payload)
      if (result && result?.id)
        this.divisions = this.divisions.concat([
          { ...payload, divisioncarrier_id: result.id },
        ])
    }

    this._loading['update.divisions'] = false
  }

  getView = () => toJS(this._view)
  setView = (view) => (this._view = `${view}`.toLowerCase())

  getIsLoading = (key) => {
    try {
      return !!toJS(this._loading)[key]
    } catch (ex) {
      return false
    }
  }
}

export default new CarrierMgmtStore()
