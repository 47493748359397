import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBContainer,
  MDBDataTable,
  MDBCardBody,
  MDBCard,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
} from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import UserProfileService from './../../shared/services/UserProfile.service'
import UsersCarrierContractService from '../../shared/services/UsersCarrierContract.service'
import UserCarrierCoverageService from './../../shared/services/UserCarrierCoverage.service'
import ContentHtml from './../../components/content/ContentHtml/ContentHtml.component'

class MaintainProducts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      coveragesList: [],
      carrierList: [],
      userRoles: {},
      modalOpen: false,
      editData: { id: '', carrierId: '', coverageId: '', url: '' },
      myCarrier: {
        columns: [
          {
            label: 'Carrier',
            field: 'carrierName',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Coverage Type',
            field: 'CarrierCoverageType',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'URL',
            field: 'url',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Edit',
            field: 'edit',
            width: 100,
          },
        ],
        rows: [],
      },
    }
  }

  componentDidMount() {
    this.fetchUserDetails()
  }

  fetchUserDetails = async () => {
    this.setState({ loading: true })
    try {
      const result = await AdminApi.fetchUserDetailsAuth()
      if (result?.data?.data) {
        this.setState({ userRoles: result.data.data })
        await this.fetchCoveragesList()
        await this.fetchCarrierList(result.data.data.id)
      }
    } catch (error) {
      toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
    } finally {
      this.setState({ loading: false })
    }
  }

  fetchCoveragesList = async () => {
    try {
      const result = await AdminApi.getCoveragesSortByName()
      if (result?.data?.data) {
        this.setState({ coveragesList: result.data.data || [] })
      }
    } catch (error) {
      toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
    }
  }

  fetchCarrierList = async () => {
    try {
      const agentContracts = await UsersCarrierContractService.search({
        search: {
          user_id: UserProfileService.getUserId(),
          disposition: 'completed',
        },
        order_by: { id: 'ASC' },
        pagination: false,
      })
      const carrierIds = agentContracts?.models.map((item) => item.carrier_id)

      const carriersResponse = await AdminApi.getCarriersList()
      if (!carriersResponse?.data?.data) return

      const carrierList = carriersResponse.data.data
      const userCoverageResponse = await UserCarrierCoverageService.search({
        search: { user_id: UserProfileService.getUserId() },
        pagination:false,
      })
      const userCoverages = userCoverageResponse?.models || []
      const coverageMap = {}
      userCoverages.forEach((item) => {
        if (!coverageMap[item.carrier_id]) {
          coverageMap[item.carrier_id] = {}
        }
        coverageMap[item.carrier_id][item.coverage_id] = {
          id: item.id,
          url: item.url,
        }
      })

      let rows = []
      carrierList.forEach((row) => {
        if (!carrierIds.includes(row.id)) return
        const { c_name, id, carriercoverages, c_website } = row
        const coverageIds = carriercoverages
          ? carriercoverages.split(',').map(Number)
          : []
        const filteredCoverages = this.state.coveragesList.filter((coverage) =>
          coverageIds.includes(coverage.id)
        )
        filteredCoverages.forEach((coverage) => {
          const existingEntry = coverageMap[id]?.[coverage.id] || {}
          rows.push({
            id: id,
            carrierName: c_name,
            CarrierCoverageType: coverage.coverage_name,
            url: existingEntry.url || row.c_website || '',
            edit: (
              <MDBBtn
                color="info"
                size="sm"
                onClick={() =>
                  this.handleEdit(
                    existingEntry.id,
                    id,
                    coverage.id,
                    existingEntry.url || row.c_website || ''
                  )
                }
              >
                <MDBIcon icon="edit" />
              </MDBBtn>
            ),
          })
        })
      })
      this.setState({ myCarrier: { ...this.state.myCarrier, rows } })
    } catch (error) {
      toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
    }
  }

  handleEdit = (id, carrierId, coverageId, url) => {
    this.setState({
      modalOpen: true,
      editData: { id, carrierId, coverageId, url },
    })
  }

  handleSave = async () => {
    const { id, carrierId, coverageId, url } = this.state.editData
    try {
      if (id) {
        await UserCarrierCoverageService.update(id, { url })
      } else {
        await UserCarrierCoverageService.store({
          user_id: UserProfileService.getUserId(),
          carrier_id: carrierId,
          coverage_id: coverageId,
          url,
        })
      }
      toast.success('URL updated successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      this.setState({ modalOpen: false })
      this.fetchCarrierList()
    } catch (error) {
      toast.error('Error updating URL', { position: toast.POSITION.TOP_RIGHT })
    }
  }

  render() {
    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection">
            {this.state.loading ? (
              <h2>Loading...</h2>
            ) : (
              <MDBContainer fluid className="mt-5">
                <h2>My Carrier Links</h2>
                <MDBCard className="mt-5 mb-5">
                  <ContentHtml contentSlug="my-carrier-links" />
                  <MDBCardBody className="mx-4 mt-4">
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      small
                      entries={50}
                      data={this.state.myCarrier}
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBContainer>
            )}
          </main>
        </DashboardLayout>
        <MDBModal
          isOpen={this.state.modalOpen}
          toggle={() => this.setState({ modalOpen: false })}
        >
          <MDBModalHeader toggle={() => this.setState({ modalOpen: false })}>
            Edit URL
          </MDBModalHeader>
          <MDBModalBody>
            <MDBInput
              label="URL"
              type="text"
              value={this.state.editData.url}
              onChange={(e) =>
                this.setState({
                  editData: { ...this.state.editData, url: e.target.value },
                })
              }
            />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => this.setState({ modalOpen: false })}
            >
              Close
            </MDBBtn>
            <MDBBtn color="primary" onClick={this.handleSave}>
              Save
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </Fragment>
    )
  }
}

export default MaintainProducts
