import RestApi from './../../api/rest-api-client'
import StorageService from './Storage.service'
import TrackJsService from './TrackJs.service'
import { formatErrorMsg } from '../formatters/errorMsgFormatter'
import firebase from 'firebase/app'
import 'firebase/auth'

const authKeys = [
  'user_fb',
  'user_details',
  'user_token',
  'prev_url',
  'parent_name',
  'parent_id',
  'parent_usertype_id',
  'child_fb',
  'child_details',
  'child_assumed_at',
  'assume_id',
]
const assumedIdKeys = [
  'child_fb',
  'child_token',
  'parent_name',
  'parent_id',
  'parent_usertype_id',
  'child_details',
  'prev_url',
  'child_assumed_at',
  'assume_id',
]

const getMe = async () => {
  try {
    return await RestApi.request('GET', '/auth/me')
  } catch (ex) {
    return `${ex}`
  }
}

const getUserDetails = async (isChild) => {
  return new Promise((resolve, reject) => {
    let user
    getMe()
      .then((u) => {
        StorageService.set(`${isChild ? 'child' : 'user'}_details`, (user = u))
        StorageService.set('modals.quote-popup', new Date().getTime())
      })
      .finally(() => {
        TrackJsService.onLogin()
        resolve(user)
      })
  })
}

const getToken = () => StorageService.getToken()

const setToken = (token) => StorageService.setToken(token)

let authChangeSubscription
async function obtainToken(isChild) {
  return new Promise((resolve, reject) => {
    const isc = isChild === true

    // unsubscribe if a handler function is defined.
    if (authChangeSubscription && typeof authChangeSubscription === 'function')
      authChangeSubscription()

    // this is an observer.  we need to kill
    // the subscription before re-subscribing.
    authChangeSubscription = firebase.auth().onAuthStateChanged(() => {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => {
          StorageService.set(`${isc ? 'child' : 'user'}_token`, token)
          resolve()
        })
    })
  })
}

const extractFbAuthToken = (tokenStr) => {
  let token, uid, assume_id
  try {
    if (typeof tokenStr === 'string')
      tokenStr = JSON.parse(decodeURIComponent(atob(tokenStr)))
    if (typeof tokenStr !== 'object') tokenStr = {}

    token = tokenStr && tokenStr?.token
    uid = tokenStr && tokenStr?.uid
    assume_id = tokenStr && tokenStr?.assume_id
  } catch (ex) {
    console.error('Fatal error attempting to decode auth token.', ex)
  }

  return { token, uid, assume_id }
}

const handleCredentialLogin = async (email, password, isChild) => {
  isChild = isChild === true
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        StorageService.set(`${isChild ? 'child' : 'user'}_fb`, res.user)
        obtainToken(isChild).finally(() =>
          getUserDetails(isChild).then((userDetails) => resolve(userDetails))
        )
      })
      .catch((error) => {
        reject(error.message)
      })
  })
}

const handleTokenLogin = async (token, uid, isChild) => {
  isChild = isChild === true
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithCustomToken(token)
      .then((res) => {
        if (res.user?.uid === uid) {
          StorageService.set(`${isChild ? 'child' : 'user'}_fb`, res.user)
          return obtainToken(isChild).finally(() =>
            getUserDetails(isChild).then(resolve)
          )
        }
        reject(
          'Failed to collect user profile from upline service.  Please try again or contact support at website.support@usabg.com for further assistance.'
        )
      })
      .catch((error) => {
        reject(error.message)
      })
  })
}

const login = async (email, password, user_ip) => {
  authKeys.forEach((k) => StorageService.clear(k))

  const _login = async (email, password) => {
      try {
        return {
          email,
          password,
          access_token: (
            await RestApi.request('POST', '/auth/login', {
              email,
              password,
              user_ip,
            })
          ).access_token,
          error: null,
        }
      } catch (ex) {
        return { email, password, access_token: null, error: `${ex}` }
      }
    },
    { access_token, error } = await _login(email, password)

  if (!error && access_token && access_token?.idToken)
    return await new Promise((resolve, reject) =>
      handleCredentialLogin(email, password).then(resolve, reject)
    )

  StorageService.clearAllKeys({
    except: ['modals.quote-popup', 'modals.marketing-investment'],
  })
  TrackJsService.track(new Error(error))
  throw new Error(
    error ||
      'Oh no! An error occured attempting to reach the server. Please try again soon.'
  )
}

const validateAuthenticated = async () => {
  let result = await RestApi.request('GET', '/auth/validate')

  // ------------------------------------------------------------------
  // THIS FUNCTIONALITY IS NOT IN USE, NOT REQUIRED,
  // OR 100% COMPLETE. ENABLE WITH CAUTION!!
  //
  // let userDetails,
  // 		email;
  //
  // if (forceRefresh) {
  // 	userDetails 					=			StorageService.get('user_details');
  // 	userDetails 					= 		userDetails && typeof userDetails === 'string' ? JSON.parse(userDetails) : userDetails;
  // 	email 								=			userDetails && userDetails?.u_email;
  // }
  //
  // let result = forceRefresh === true
  // 								? await RestApi.request("POST", '/auth/validate', {email})
  // 								: await RestApi.request("GET", '/auth/validate', {email});
  // ------------------------------------------------------------------

  if (result && typeof result === 'object' && result?.id && result?.usertype_id)
    return { id: result?.id, usertype_id: result?.usertype_id }

  return false
}

const logout = async () => {
  try {
    await RestApi.request('POST', '/auth/logout')
  } catch (ex) {
    console.error(`Failed to record tracking event. ${ex}.`)
  }

  StorageService.clearAllKeys({
    except: ['modals.quote-popup', 'modals.marketing-investment'],
  })

  return firebase
    .auth()
    .signOut()
    .catch((error) => error.message)
}

const sendResetPasswordLink = async (email) => {
  try {
    return await RestApi.request('POST', '/auth/password/send-link', { email })
  } catch (ex) {
    console.error('Failed to send reset password link. ', ex)
    throw Error(ex)
  }
}

const resetPassword = async (actionCode, apiKey, password) => {
  try {
    return await RestApi.request('POST', '/auth/password/reset', {
      actionCode,
      password,
      apiKey,
    })
  } catch (ex) {
    console.error('Failed to reset password. ', ex)
    throw Error(ex)
  }
}

const manualResetPassword = async (userId, password) => {
  try {
    return await RestApi.request('POST', '/auth/password/manual-reset', {
      user_id: userId,
      password,
    })
  } catch (ex) {
    console.error('Failed to reset password. ', ex)
    throw Error(ex)
  }
}

const assumeUser = async (userId) => {
  const _login = async (userId) => {
      let result
      try {
        result = await RestApi.request('POST', '/auth/assume/' + userId)
      } catch (ex) {
        result = `${ex}`
        return {
          user_id: userId,
          token: null,
          uid: null,
          assume_id: null,
          error: result,
        }
      }

      let { token, uid, assume_id } = extractFbAuthToken(
        result && result?.token
      )

      if (result && result?.status === 'success')
        return { user_id: userId, token, uid, assume_id, error: false }
      return { user_id: userId, token, uid, assume_id: null, error: result }
    },
    { token, uid, assume_id, error } = await _login(userId)

  if (token && uid) {
    let userDetails = StorageService.get('user_details')
    userDetails =
      userDetails && typeof userDetails === 'string'
        ? JSON.parse(userDetails)
        : userDetails

    // Track upcoming changes to local storage.
    let results,
      storage = {
        prev_url: `${window.location.pathname}${window.location.search}`,
        parent_name: `${userDetails?.u_fname} ${userDetails?.u_lname}`,
        parent_id: parseInt(userDetails?.id),
        parent_usertype_id: parseInt(userDetails?.usertype_id),
        assume_id,
      }

    try {
      results = await handleTokenLogin(token, uid, true)
    } catch (ex) {
      results = `${ex}`
      throw Error(
        'Unable to assume as selected user. You are already assuming an agent'
      )
    }

    if (results && typeof results === 'object' && results?.id) {
      StorageService.clear('user---required-alerts')
      StorageService.set('child_details', JSON.stringify(results))
      StorageService.set('child_assumed_at', new Date().getTime())

      Object.keys(storage).forEach((key) =>
        StorageService.set(key, storage[key])
      )

      return true
    }

    return false
  }
  throw Error(formatErrorMsg(error) || 'Unable to login as selected user.')
}

const unassumeUser = async () => {
  // 1. clear local storage values.
  // If NOTHING else goes right, this will force user to re-login.
  let path = StorageService.get('prev_url'),
    assumeId = StorageService.get('assume_id')
  assumedIdKeys.forEach((k) => StorageService.clear(k))
  StorageService.clear('user---required-alerts')

  // 2. collect the (parent) user details & the unique assume_id.
  if (assumeId) {
    let attempt
    try {
      attempt = await RestApi.request('POST', '/auth/unassume/' + assumeId)
    } catch (ex) {
      return false
    }

    if (attempt && typeof attempt === 'object' && attempt?.token) {
      let { token, uid } = extractFbAuthToken(attempt && attempt?.token),
        results = false

      if (token && uid) {
        try {
          results = await handleTokenLogin(token, uid, false)
        } catch (ex) {
          results = `${ex}`
        }
      }

      if (results && typeof results === 'object' && results?.id)
        return path ? `${path}`.trim() : '/dashboard'
    }

    return false
  }

  // this is the fallback.
  let userDetails = StorageService.get('user_details')
  userDetails =
    userDetails && typeof userDetails === 'string'
      ? JSON.parse(userDetails)
      : userDetails

  let unassumed = await validateAuthenticated()
  if (
    unassumed &&
    unassumed?.id &&
    userDetails &&
    userDetails?.id &&
    `${unassumed.id}` === `${userDetails?.id}`
  )
    return path ? `${path}`.trim() : '/dashboard'

  return false
}

const refreshToken = async () => {
  await new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user || !user?.uid) return reject('NO_USER')

      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => {
          setToken(token)
          resolve()
        })
        .catch(reject)
    })
  })
}

const AuthService = {
  login,
  logout,
  assumeUser,
  unassumeUser,
  resetPassword,
  sendResetPasswordLink,
  getToken,
  refreshToken,
  validateAuthenticated,
  manualResetPassword,
}

export default AuthService
